<template>
  <div v-if="componentLoading">
    <div
      class="bg-black position-fixed"
      style="width: 100%; height: 100%; z-index: 4; opacity: 0.1"
    ></div>

    <div
      class="card p-5 position-fixed"
      style="
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <img
        class="d-block mx-auto"
        style="width: 20%"
        src="@/assets/loading.gif"
      />
      <h1 style="color: black" class="text-dark text-center my-4">
        {{ componentLoadingMessage }}
      </h1>
    </div>
  </div>
  <AppLayout>
    <main>
      <div class="page-header pb-10 page-header-dark bg-primary">
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>Booked Policy Certificates</span>
            </h1>
            <div class="page-header-subtitle ml-2">
              All Booked Marine Policy Certificates
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">
            Booked Policy Certificates
            <router-link to="/broker/buymarine" class="btn btn-primary ml-auto"
              >Buy Marine Policy</router-link
            >
          </div>
          <div class="card-body">
            <div v-if="pageLoading && bookedBusinesses.length == 0">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/loader.gif"
              />
            </div>
            <div v-else-if="bookedBusinesses.length == 0">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/nodata.svg"
              />
              <p class="text-center">
                No data currently available
                <router-link to="/broker/buymarine"
                  >Book New Marine Policy</router-link
                >
                to continue
              </p>
            </div>
            <div v-else class="datatable table-responsive">
              <table
                class="table table-bordered table-hover"
                id="dataTable"
                cellspacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>Date Booked</th>
                    <th>Insured Name</th>
                    <th>Certificate No</th>
                    <th>Certificate Type</th>
                    <th>Phone Number</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Date Booked</th>
                    <th>Insured Name</th>
                    <th>Certificate No</th>
                    <th>Certificate Type</th>
                    <th>Phone Number</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr v-for="business in bookedBusinesses" :key="business.id">
                    <td>{{ business.submittedOn.split("T")[0] }}</td>
                    <td>{{ business.insuredName }}</td>
                    <td>{{ business.certifateNo }}</td>
                    <td>{{ business.certificateType }}</td>
                    <td>{{ business.phoneNo }}</td>
                    <td>{{ business.from }}</td>
                    <td>{{ business.to }}</td>
                    <td>
                      <span
                        :class="[
                          business.tranrsactionreference !== null &&
                          business.tranrsactionreference !== ''
                            ? 'text-success'
                            : 'text-danger',
                        ]"
                      >
                        {{
                          business.tranrsactionreference !== null &&
                          business.tranrsactionreference !== ""
                            ? "Paid"
                            : "Pending Payment"
                        }}
                      </span>
                    </td>

                    <td>
                      <router-link
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View Business"
                        class="btn btn-datatable btn-transparent-dark mr-2"
                        :to="{
                          name: 'BookedBusiness',
                          params: { id: business.certifateNo },
                        }"
                      >
                        <i class="bi bi-eye-fill" style="font-size: 1.2rem"></i
                      ></router-link>

                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Rebook Business"
                        class="btn btn-datatable btn-transparent-dark mr-2"
                        @click.prevent="getClient(business.policyno)"
                      >
                        <i
                          style="font-size: 1.2rem"
                          class="bi bi-clipboard-plus"
                        ></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>



<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useMarineStore } from "../../../stores/marine";
import $ from "jquery";
import { useMainStore } from "../../../stores/main";

export default {
  name: "Users",
  components: { AppLayout },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useMarineStore, [
      "pageLoading",
      "componentLoading",
      "componentLoadingMessage",
      "bookedBusinesses",
    ]),
    ...mapState(useMainStore, ["user"]),
  },

  methods: {
    ...mapActions(useMarineStore, ["getBookedBusinesses", "getClient"]),
  },

  created() {
    if (this.user.role !== "Broker") {
      this.$router.go(-1);
    }
    $(document).ready(function () {
      $("#dataTable").DataTable();
    });
    this.getBookedBusinesses().then(() => {
      $(document).ready(function () {
        $("#dataTable").DataTable();
      });
    });
  },
};
</script>